import React, { useState } from 'react';
import './App.css';

function App() {
  const [showAbout, setShowAbout] = useState(false);

  // Toggle About message on click
  const handleAboutClick = () => {
    setShowAbout(!showAbout);
  };

  return (
    <div className="App">
      {/* Logo in the top-left */}
      <img src="Helmvik_Astronaut_Helmet_Logo_Transparent.png" alt="Hemvik Logo" className="logo" />

      {/* About link in the bottom-left */}
      <button className="about-link" onClick={handleAboutClick}>
        What is Helmvik?
      </button>

      {/* Main content */}
      <div className="content">
        {!showAbout ? (
          <>
            <h1>
              Helmvik<span className="blinking-underscore">_</span>
            </h1>
          </>
        ) : (
          <p className="about-message">
            Just a bunch of engineers and founders building a software for the Nordics and beyond.
            <br />
            <br />
            Made in the Nordics ❤️
          </p>
        )}
      </div>
    </div>
  );
}

export default App;
